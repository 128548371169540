import React, {useEffect} from "react";
import envConfig from "../../../Configs/env.config";

//assets import
import {ReactComponent as ClockIcon} from "../../../Assests/Icons/clock.svg";
import parse from "html-react-parser";
import {ROUTE_CONSTANTS} from "../../../Routes/RouteConstants";

type Props = {
    article: any
}

const BlogDetailNew = ({article}: Props) => {
    useEffect(() => {
        if (!article.articles[0]) return;

        
        document.querySelector('meta[name="description"]')?.setAttribute("content", article.articles[0]?.translations[0]?.meta_description || "");

        if (article.articles[0]?.nofollow || article.articles[0]?.noindex) {
            const meta = document.createElement('meta');
            meta.name = "robots";

            let values = [];
            if (article.articles[0]?.nofollow) {
                values.push("nofollow");
            }
            if (article.articles[0]?.noindex) {
                values.push("noindex");
            }

            meta.content = values.join(",");

            document.getElementsByTagName('head')[0].appendChild(meta);
        }


        document.title = article.articles[0]?.translations[0]?.meta_title || article.articles[0]?.translations[0]?.title || "";
    }, [article.articles]);

    return (
        <div>
            {
                !article.articles[0] ? <div
                        className="text-xl text-center p-32"
                    >
                        <h4>Article Not Found</h4>
                    </div> :
                    <div>
                        <div className="font-sans text-base font-normal text-deep-black opacity-50">
                            <span
                                className="underline cursor-pointer"
                                onClick={() => window.location.href = ROUTE_CONSTANTS.BLOG}
                            >Main Blog</span> {">"} <span>
                            {article.articles[0]?.translations[0]?.title}
                        </span>
                        </div>

                        <h1 className="font-sans text-5xl font-bold text-deep-black mt-6">{article.articles[0]?.translations[0]?.title}</h1>

                        <div className="flex justify-center my-6">
                            <img
                                src={`${envConfig.REACT_APP_DIRECTUS_BASE_URL}assets/${article.articles[0]?.image?.id}`}
                                alt="Blog Image"
                                className="w-full h-44 object-cover rounded-md"
                            />
                        </div>

                        <h5 className="text-deep-black font-sans font-normal">
                            <ClockIcon className="inline-block me-3"/>
                            <span className="text-orange text-sm me-3">Updated</span>
                            <span className="opacity-50 text-sm me-3">
                                   {
                                       (article.articles[0]?.published_date) &&
                                       new Date(article.articles[0].published_date).toLocaleDateString('en-US', {
                                           month: 'long',
                                           day: '2-digit',
                                           year: 'numeric'
                                       })
                                   }
                                </span>

                            <span className="opacity-50 text-sm">
                                    <span>Author: </span>
                                    <span className="underline">
                                        {`${article.articles[0]?.author?.name}`}
                                    </span>
                                </span>
                        </h5>

                        <hr className="divide-y-2 divide-gray-light mb-6 mt-8"/>

                        <h5 className="font-sans text-3xl leading-relaxed font-bold text-gray-darkest">
                            {article.articles[0]?.translations[0]?.title}
                        </h5>

                        <div className="font-sans text-base font-normal text-gray-darkest mt-6">
                            {parse(article.articles[0]?.translations[0]?.content || "")}
                        </div>
                    </div>
            }
        </div>
    );
}

export default BlogDetailNew;